(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("SBTech"), require("sbBffIntegration"), require("sbJsExtends"), require("mobxReact"), require("gsap"), require("sbInternalMsgBus"), require("ReactDOM"), require("sbRespBlockLib"), require("Draggable"), require("mobxUtils"));
	else if(typeof define === 'function' && define.amd)
		define("sb-responsive-super-banner-block", ["React", "mobx", "SBTech", "sbBffIntegration", "sbJsExtends", "mobxReact", "gsap", "sbInternalMsgBus", "ReactDOM", "sbRespBlockLib", "Draggable", "mobxUtils"], factory);
	else if(typeof exports === 'object')
		exports["sb-responsive-super-banner-block"] = factory(require("React"), require("mobx"), require("SBTech"), require("sbBffIntegration"), require("sbJsExtends"), require("mobxReact"), require("gsap"), require("sbInternalMsgBus"), require("ReactDOM"), require("sbRespBlockLib"), require("Draggable"), require("mobxUtils"));
	else
		root["sb-responsive-super-banner-block"] = factory(root["React"], root["mobx"], root["SBTech"], root["sbBffIntegration"], root["sbJsExtends"], root["mobxReact"], root["gsap"], root["sbInternalMsgBus"], root["ReactDOM"], root["sbRespBlockLib"], root["Draggable"], root["mobxUtils"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__7__, __WEBPACK_EXTERNAL_MODULE__9__, __WEBPACK_EXTERNAL_MODULE__14__, __WEBPACK_EXTERNAL_MODULE__15__, __WEBPACK_EXTERNAL_MODULE__18__, __WEBPACK_EXTERNAL_MODULE__23__, __WEBPACK_EXTERNAL_MODULE__24__, __WEBPACK_EXTERNAL_MODULE__25__, __WEBPACK_EXTERNAL_MODULE__56__) {
return 